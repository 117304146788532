export const downloadOrderFile = {
  methods: {
    async handleDownloadFile(type, orderId) {
      const response = await this.$http.get(`/b2b/${type}/id/${orderId}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${type}-${orderId}.pdf`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
  },
}

export const _ = null
